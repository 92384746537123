export const checkLocation = () => {
  const url = window.location.hostname;
  let appLinks = {};
  if (!url.includes('musicinsights')) {
  if (url.includes('-dev') || url.includes('localhost')) {
    appLinks = {
      appName: 'The UM-X Suite',
      uma: {
        link: 'https://artists-dev.umusic.com',
        name: 'Universal Music Artists'
      },
      uml: {
        link: 'https://labels-dev.umusic.com',
        name: 'Universal Music Labels'
      },
      umc: {
        link: 'https://consumption-dev.umusic.com',
        name: 'Universal Music Consumption'
      },
      umd: {
        link: 'https://discovar.dev.umusic.com',
        name: 'Universal Music Discover'
      }
    }
    return appLinks;
  }
  if (url.includes('-qa')) {
    appLinks = {
      appName: 'The UM-X Suite',
      uma: {
        link: 'https://artists-qa.umusic.com',
        name: 'Universal Music Artists'
      },
      uml: {
        link: 'https://labels-qa.umusic.com',
        name: 'Universal Music Labels'
      },
      umc: {
        link: 'https://consumption-qa.umusic.com',
        name: 'Universal Music Consumption'
      },
      umd: {
        link: 'https://discovar.dev.umusic.com',
        name: 'Universal Music Discover'
      }
    }
    return appLinks;
  }
  if (url.includes('-uat')) {
    appLinks = {
      appName: 'The UM-X Suite',
      uma: {
        link: 'https://artists-uat.umusic.com',
        name: 'Universal Music Artists'
      },
      uml: {
        link: 'https://labels-uat.umusic.com',
        name: 'Universal Music Labels'
      },
      umc: {
        link: 'https://consumption-uat.umusic.com',
        name: 'Universal Music Consumption'
      },
      umd: {
        link: 'https://discovar.dev.umusic.com',
        name: 'Universal Music Discover'
      }
    }
    return appLinks;
  }
  appLinks = {
    appName: 'The UM-X Suite',
    uma: {
      link: 'https://artists.umusic.com',
      name: 'Universal Music Artists'
    },
    uml: {
      link: 'https://labels.umusic.com',
      name: 'Universal Music Labels'
    },
    umc: {
      link: 'https://consumption.umusic.com',
      name: 'Universal Music Consumption'
    },
    umd: {
      link: 'https://discovar.umusic.com',
      name: 'Universal Music Discover'
    }
  }
  return appLinks;
  }
  if (url.includes('musicinsights')) {
  if (url.includes('dev')) {
    appLinks = {
      appName: 'The MI Suite',
      uma: {
        link: 'https://artists-dev.musicinsights.com',
        name: 'Music Insights - Artists'
      },
      uml: {
        link: 'https://labels-dev.musicinsights.com',
        name: 'Music Insights - Labels'
      },
      umc: {
        link: 'https://consumption-dev.musicinsights.com',
        name: 'Music Insights - Consumption'
      },
      umd: {
        link: 'https://discovar.dev.umusic.com',
        name: 'Music Insights - Discover'
      }
    }
    return appLinks;
  }
  if (url.includes('qa')) {
    appLinks = {
      appName: 'The MI Suite',
      uma: {
        link: 'https://artists-qa.musicinsights.com',
        name: 'Music Insights - Artists'
      },
      uml: {
        link: 'https://labels-qa.musicinsights.com',
        name: 'Music Insights - Labels'
      },
      umc: {
        link: 'https://consumption-qa.musicinsights.com',
        name: 'Music Insights - Consumption'
      },
      umd: {
        link: 'https://discovar.dev.umusic.com',
        name: 'Music Insights - Discover'
      }
    }
    return appLinks;
  }
  if (url.includes('uat')) {
    appLinks = {
      appName: 'The MI Suite',
      uma: {
        link: 'https://artists-uat.musicinsights.com',
        name: 'Music Insights - Artists'
      },
      uml: {
        link: 'https://labels-uat.musicinsights.com',
        name: 'Music Insights - Labels'
      },
      umc: {
        link: 'https://consumption-uat.musicinsights.com',
        name: 'Music Insights - Consumption'
      },
      umd: {
        link: 'https://discovar.dev.umusic.com',
        name: 'Music Insights - Discover'
      }
    }
    return appLinks;
  }
  appLinks = {
    appName: 'The MI Suite',
    uma: {
      link: 'https://artists.musicinsights.com',
      name: 'Music Insights - Artists'
    },
    uml: {
      link: 'https://labels.musicinsights.com',
      name: 'Music Insights - Labels'
    },
    umc: {
      link: 'https://consumption.musicinsights.com',
      name: 'Music Insights - Consumption'
    },
    umd: {
      link: 'https://discovar.umusic.com',
      name: 'Music Insights - Discover'
    }
  }
  return appLinks;
  }
}
