import React, { Component } from 'react';
import './App.css';
import { checkLocation } from './helpers';

class App extends Component {

  constructor(props){
    super(props);
    document.title = window.location.hostname.includes('musicinsights') ? 'The MI Suite' : 'The UM-X Suite';
  }

  render() {
    return (
      <div
        className="container-fluid"
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: 'url("./screen-1-lg.jpg")',
          backgroundColor: '#080C10',
          minHeight: '100%',
          paddingTop: '3%'
        }}
      >
      <div className="row justify-content-center">
      <div
        className="col-12 text-center pb-5"
        style={{
          fontSize: '32px',
          fontFamily: 'DIN W03 Black'
        }}
      >
        {checkLocation().appName}
      </div>
      </div>
        <div className="row justify-content-center">
          {!window.location.hostname.includes('musicinsights') && <div className="col-sm-12 col-md-4">
            <div className="card-wrapper row">
            <div className="col-1">
            <div
              className="app-icon"
              style={{
                backgroundColor: '#FF8BA3'
              }}
            >
            </div>
            </div>
            <div className="col-10 text-left app-name">
              {checkLocation().uma.name}
            </div>
            <div className="col-12 pt-2 app-info">
              One single place for artists and managers to get data on who their fans are, what their fans are listening to, where they are listening, and how they are listening.
            </div>
            <div
              className="col-12 text-left"
            >
            <a
              href={checkLocation().uma.link}
              style={{
                color: '#FF8BA3'
              }}
            >
              Visit UMA
            </a>
            </div>
            </div>
          </div>}
          <div className="col-sm-12 col-md-4">
            <div className="card-wrapper row">
            <div className="col-1">
            <div
              className="app-icon"
              style={{
                backgroundColor: '#2FFFC8'
              }}
            >
            </div>
            </div>
            <div className="col-10 text-left app-name">
              {checkLocation().uml.name}
            </div>
            <div className="col-12 pt-2 app-info">
              One single place for labels to get data on who their fans are, what their fans are listening to, where they are listening, and how they are listening. Everything you’ll need, at your fingertips.
            </div>
            <div
              className="col-12 text-left"
            >
            <a
              href={checkLocation().uml.link}
              style={{
                color: '#2FFFC8'
              }}
            >
              Visit UML
            </a>
            </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
        <div className="col-sm-12 col-md-4">
          <div className="card-wrapper row">
          <div className="col-1">
          <div
            className="app-icon"
            style={{
              backgroundColor: '#0BB8E1'
            }}
          >
          </div>
          </div>
          <div className="col-10 text-left app-name">
              {checkLocation().umc.name}
          </div>
          <div className="col-12 pt-2 app-info">
            Includes units, release to date figures, physical shipments, downloads, and streams.
          </div>
          <div
            className="col-12 text-left"
          >
          <a
            href={checkLocation().umc.link}
            style={{
              color: '#0BB8E1'
            }}
          >
            Visit UMC
          </a>
          </div>
          </div>
        </div>
          {!window.location.hostname.includes('musicinsights') && <div className="col-sm-12 col-md-4">
            <div className="card-wrapper row">
            <div className="col-1">
            <div
              className="app-icon"
              style={{
                backgroundColor: '#F98851'
              }}
            >
            </div>
            </div>
            <div className="col-10 text-left app-name">
              {checkLocation().umd.name}
            </div>
            <div className="col-12 pt-2 app-info">
              Discover is an A&R platform, showing only unsigned and non-major label content. Discover will help you find the next big thing.
            </div>
            <div
              className="col-12 text-left"
            >
            <a
              href={checkLocation().umd.link}
              style={{
                color: '#0BB8E1'
              }}
            >
              Visit UMD
            </a>
            </div>
            </div>
          </div>}
        </div>
        </div>
    );
  }
}

export default App;
